import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { FiSearch } from "react-icons/fi";

import { FaCheck, FaAngleUp, FaAngleDown } from "react-icons/fa6";

import classes from "./AssetDropdown.module.css";
import useOnClickOutside from "hooks";
import {
  avaxLogo,
  bnbLogo,
  btcLogo,
  daiLogo,
  dashLogo,
  dogeLogo,
  ethLogo,
  ltcLogo,
  usdtLogo,
} from "images";
import { Heading, Text } from "components/common";
const listItems = [
  { img: usdtLogo, name: "USDT BSC", price: "0.00", now_payments_id: "usdtbsc" },
  // { img: avaxLogo, name: "AVAX", price: "0.00" },
  // { img: bnbLogo, name: "BNB", price: "0.00" },
  // { img: btcLogo, name: "BTC", price: "0.00" },
  // { img: daiLogo, name: "DAI", price: "0.00" },
  // { img: dashLogo, name: "DASH", price: "0.00" },
  // { img: ethLogo, name: "ETH", price: "0.00" },
  // { img: ltcLogo, name: "LTC", price: "0.00" },
  // { img: dogeLogo, name: "DOGE", price: "0.00" },
];
const AssetDropdown = ({
  isActive,
  setIsActive,
  selectedValue,
  onSelect,
  type2,
}) => {
  const [filteredItems, setFilteredItems] = useState(listItems);
  const inputRef = useRef();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    inputRef.current.focus();
  }, [isActive]);

  useEffect(() => {
    setFilteredItems(
      listItems?.filter((el) =>
        el.name?.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue]);
  const ref = useRef();

  useOnClickOutside(ref, () => setIsActive(false));
  return (
    <div
      className={clsx(classes.dropdown, type2 && classes.dropdown2)}
      ref={ref}
    >
      <div
        className={clsx(
          classes.labelContainer,
          type2 && classes.labelContainer2
        )}
        onClick={() => setIsActive((prev) => !prev)}
      >
        {selectedValue ? (
          <div className={clsx(classes.selectedValue)}>
            <img src={selectedValue.img} alt="#" className={classes.img} />
            <Heading base className={classes.left}>
              {selectedValue.name}
            </Heading>
          </div>
        ) : (
          <Text primitive700 base semiBold>
            Select
          </Text>
        )}
        {isActive ? (
          <FaAngleUp className={classes.arrow} />
        ) : (
          <FaAngleDown className={classes.arrow} />
        )}
      </div>
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.searchContainer}>
          <input
            ref={inputRef}
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />{" "}
          <FiSearch className={classes.searchIcon} />
        </div>
        <div className={classes.list}>
          {filteredItems?.map((el, idx) => {
            return (
              <div
                key={"lang-list-item-" + idx}
                className={clsx(
                  classes.listItem,
                  selectedValue.name === el.name && classes.active
                )}
                onClick={() => {
                  setSearchValue("");
                  onSelect(el);
                  setIsActive(false);
                }}
              >
                <img src={el.img} alt="#" className={classes.img} />
                <p className={classes.left}>{el.name}</p>

                {selectedValue.name === el.name && (
                  <FaCheck className={classes.checkmark} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AssetDropdown;
